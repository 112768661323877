.input-container {
    display: flex;
    margin-top: 10px;
    /* Utilizamos flexbox para los campos de texto en línea */
}

.input-text {
    flex: 1;
    /* Para que los campos de texto ocupen el mismo ancho y se posicionen uno al lado del otro */
    margin-right: 18px;
    /* Espacio entre los campos de texto */
}

.boton-container{
    margin-top: 10px;
    margin-left: 7px;
}

.modal-imagen{
    width: 80px;
    height: 80px;
}
.tamano-imagen{
    width: 200px;
    height: 200px;
    margin: 10%;
}
.boton-camara{
    width: auto;
    height: auto;
    align-items: center;
}